import React from 'react';
import ServicesPage from './../../components/ServicesPage';
import { TextSection, Exclamation, Declaration, UL, LI, SectionText, SubHeading } from '../../components/textComponents';
import styled from 'styled-components';

import theme from './../../data/theme';

import fertilizerPhoto from '../../../static/assets/lush_grass.jpg';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
  'Fertilizer',
  'Fertilization',
  'Lawn Care',
  'Lawn',
];

const page = {
  title: 'Fertilization',
  keywords: keywords,
  description: 'VLP Landscaping can handle all of your lawn fertilization needs, to give you the yard of your dreams. We can treat your grass for crabgrass, weeds, and pests.',
};

const heading = {
  title: page.title,
  img: fertilizerPhoto,
  alt: "Beautiful green grass"
};

const LawnCareProgram = styled.div`
  padding-left: 2rem;
`;

const SessionSeason = styled.h4`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 3rem;
  display: inline-block;
  margin-right: 1rem;
`;

const SessionDescription = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 0;
`;

const SessionHighlight = styled.p`
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: ${ theme.colors.cloudPrimary };
  line-height: 1.5;
  display: inline-block;
`;

const SessionNote = styled.p`
  margin: 0;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bold;
  line-height: 1.5;
`;

export default () => (
  <ServicesPage page={page} heading={heading}>
    <TextSection>
      <Declaration>
        Come and grow with our new fertilization lawn care program, tailored for you.  We use products that are designed to promote healthy root development, to leave you with a lush and healthy lawn.
      </Declaration>
      <UL>
        <LI>Don't have time to regularly fertilize your lawn?</LI>
        <LI>Forget when to apply treatments?</LI>
        <LI>Worried about storing chemicals in your garage or home?</LI>
      </UL>
      <Exclamation css={`
        margin: 4rem 0 5rem;
      `}>
        LEAVE THE DIRTY WORK TO US!
      </Exclamation>

      <SubHeading>
        Free Lawn Evaluation
      </SubHeading>
      <SectionText>
        Every Lawn is different, and we will walk your lawn with our certified technician to do a pre-application inspection. We will be able to set your lawn up with the best package for you.
      </SectionText>

      <SubHeading>
        6-Star Lawn Program
      </SubHeading>
      <SectionText>
        Premium Lawn Care Fertilization which includes Nitrogen, Phosphorus and Potassium, weed control and one application of Crabgrass Control.
      </SectionText>
      <LawnCareProgram>
        <SessionSeason>Early Spring</SessionSeason>
        <SessionDescription>
          Granular Fertilizer + Crabgrass Prevention + Weed Control
        </SessionDescription>

        <SessionSeason>Late Spring</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Total Spray Weed Control
        </SessionDescription>

        <SessionSeason>Early Summer</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Spot Spray Weed Control
        </SessionDescription>

        <SessionSeason>Late Summer</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Spot Spray Weed Control
        </SessionDescription>

        <SessionSeason>Early Fall</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer +Total Spray Weed Control
        </SessionDescription>

        <SessionSeason>Late Fall</SessionSeason>
        <SessionHighlight>
          FREE LAWN EVALUATION
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Spot Spray Weed Control
        </SessionDescription>
      </LawnCareProgram>

      <SubHeading>
        7-Star Lawn Program
      </SubHeading>
      <SectionText>
        Premium Granular Lawn Care Fertilization which includes Nitrogen, Phosphorus and Potassium, weed control, one application each of Crabgrass Control, Grub Prevention, and Insecticide.
      </SectionText>
      <LawnCareProgram>
        <SessionSeason>Early Spring</SessionSeason>
        <SessionDescription>
          Granular Fertilizer + Crabgrass Prevention + Weed Control
        </SessionDescription>

        <SessionSeason>Late Spring</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Total Spray Weed Control
        </SessionDescription>
        <SessionNote>
          *Includes: Grub Prevention Application*
        </SessionNote>

        <SessionSeason>Early Summer</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Spot Spray Weed Control
        </SessionDescription>
        <SessionNote>
          *Includes: Talstar Granular Insecticide*
        </SessionNote>

        <SessionSeason>Late Summer</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Spot Spray Weed Control
        </SessionDescription>

        <SessionSeason>Early Fall</SessionSeason>
        <SessionHighlight>
          FREE SERVICE CALLS
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Total Spray Weed Control
        </SessionDescription>

        <SessionSeason>Late Fall</SessionSeason>
        <SessionHighlight>
          FREE LAWN EVALUATION
        </SessionHighlight>
        <SessionDescription>
          Granular Fertilizer + Spot Spray Weed Control
        </SessionDescription>
      </LawnCareProgram>
    </TextSection>
  </ServicesPage>
);
